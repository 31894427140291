.full-size-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: white !important;
}

.home-first{
    background-color: white;
    position: relative;
    width: 100vw;
    height: 100vh;
    min-height: 800px;
    max-width:100%;
    z-index: 0;
}

.front-desk-text{
    text-align:center;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: black 2px 0 10px;
    color: white;
}

.front-desk-text h1{
    font-size: 50px;
}
.front-desk-text h2{
    margin-bottom: 20px;
}
.front-desk-text a{
    text-decoration: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 12px;
    transition: background-color 300ms ease-out 100ms
}

.front-desk-text a:hover{
    background-color: black;
}