.outer-contact{
    width: 100vw;
    height: fit-content;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.full-map{
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 20px 20px;
    object-fit: cover;
}

.inner-contact{
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.inner-contact h2{
    margin: 0px;
}

.inner-contact hr{
    width: 100%;
}

@media only screen and (max-width: 400px){
    .inner-contact h1{
        font-size: 25px;
    }
}