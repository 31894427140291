.outer-about{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: fit-content;
    margin-top: 120px;
}
.inner-about{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    padding: 10px;
    box-sizing: border-box;
}

.inner-about hr{
    width: 100%;
}