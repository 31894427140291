.services{
    margin-top: 140px;
    height: fit-content;
    max-width: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-grid{
    padding: 0px 30px;
    display: grid;
    width: 100%;
    max-width: 1000px;
    height: fit-content;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    box-sizing: border-box;
}

.highlighted-service{
    padding: 0px 30px;
    margin: 1rem 0px;
    width: 100%;
    max-width: 1000px;
    height: fit-content;
    box-sizing: border-box;
}

@media only screen and (max-width:600px){
    .service-grid{
        grid-template-columns: 1fr;
    }
}