.OT{
    margin-top: 140px;
    height: fit-content;
    max-width: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inner-OT{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
    max-width: 1000px;
    margin: 0px 10px
}

.inner-OT li{
    margin-bottom: 5px;
    font-size: 20px;
}