.navbar-top{
    z-index: 40;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    background-color: white;
    backdrop-filter: blur(10px);
    width: 100vw;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 10px black;
}

.inner-nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    width: 100%;
    max-width: 1200px;
    margin: 0px 10px;
}

.logo-text{
    font-size: 30px;
    margin: 0;
    width: fit-content;
}

.branding {
    display: flex;
    align-items: center;
    margin: 0px
}

.branding-text{
    display: flex;
    flex-direction: column;
}

.navbar-top a {
    color: black !important;
    text-decoration: none !important;
    transition: color 300ms ease-out 100ms !important
}

.navlink-text{
    text-align: center;
    font-size: 30px;
    margin: 0px;
}

.navbar-top a:hover{
    color: gray !important;
}

.dropdown-nav{
    display: none;
}

.dropdown-button{
    display: none;
}

@media only screen and (max-width: 1100px){
    .inner-nav{
        gap: 20px;
    }
}
@media only screen and (max-width: 950px){
    .logo-text{
        font-size: 20px;
    }
    .navlink-text{
        font-size: 24px;
    }
}

@media only screen and (max-width: 800px){
    .logo-text{
        display: none;
    }
}

@media only screen and (max-width: 640px){
    .navlink-text{
        display: none;
    }
    .dropdown-button {
        display: flex;
        justify-content: center;
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        border: 0;
        border-radius: 10px;
        padding: 10px;
        transition: background-color 500ms ease-out 100ms;
    }

    .dropdown-button:hover {
        background-color: white;
    }

    .dropdown-button:hover .dropdown-nav{
        display: block;
        position: absolute;
        right: 0;
        top: 80px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        background-color: white;
        backdrop-filter: blur(20px);
        border-radius: 10px;
        padding: 0px 10px;
        margin-right: 10px;
    }
    .navlink-text-drop{
        display: block;
    }

}

