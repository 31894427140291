.service-card{
    width: fit-content;
    height: 100%;
    box-shadow: 0 0 5px black;
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-card a {
    text-decoration: none;
    color: black;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: solid;
}

.service-card button{
    background-color: white;
    padding: 10px;
    border-radius: 10px;

}

.new{
    align-self: first baseline;
    width: 50px;
    height: 20px;
    background: greenyellow;
    text-align: center;
    border-radius: 5px;
}